<template>
  <div
    class="zicenter"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>中心类型</span>
        <el-select class="select" v-model="value" placeholder="管理中心">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>用户分组</span>
        <el-select class="select" v-model="value" placeholder="客服专员">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字查询</span>
        <el-select class="select" v-model="value" placeholder="用户姓名">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="id"
          label="中心编号"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="center"
          label="中心名称"
          width="100"
        ></el-table-column>
        <el-table-column prop="zhuwu" label="中心类型"></el-table-column>
        <el-table-column prop="name" label="负责人"></el-table-column>
        <el-table-column prop="tel" label="联系电话"></el-table-column>
        <el-table-column prop="time" label="客户数量"></el-table-column>
        <el-table-column prop="termnum" label="终端数量"></el-table-column>
        <el-table-column prop="servicenum" label="客服数量"></el-table-column>
        <el-table-column prop="caozuo" label="操作">
          <template>
            <el-button class="details" size="mini">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="total">
      <!-- 分页 -->
      <div class="pages">
        <el-pagination
          :page-size="pagesize"
          :pager-count="pagercount"
          layout="slot, pager"
          :total="100"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <img class="lefts" src="../../assets/images/leftpage.png" alt />
          <img class="rights" src="../../assets/images/rightpage.png" alt />
        </el-pagination>
      </div>
      <!-- 按钮 -->
      <div class="buttons">
        <el-button>添加子数据</el-button>
        <el-button>数据导出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      pagesize: 8,
      pagercount: 5,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      //   table
      tableData: [
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2058",
          termnum: "2020",
          servicenum: "16",
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.zicenter {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 40px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .el-input__inner {
        border-radius: 0;
        height: 33px;
        line-height: 33px;
      }
      .el-input__icon {
        height: 100%;
        width: 25px;
        text-align: center;
        transition: all 0.3s;
        line-height: 33px;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    margin-left: 25px;
    margin-right: 40px;
    .tingyong {
      color: rgb(97, 97, 245);
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
  }
  .total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
    margin-right: 100px;
    // 按钮
    .buttons {
      display: flex;
      .el-button {
        width: 130px;
        height: 40px;
        line-height: 40px;
        border-radius: 25px;
        margin-top: 20px;
        margin-right: 20px;
        background: #d78673;
        color: #ffffff;
        font-size: 16px;
        padding: 0px;
      }
    }
    // page
    .pages {
      display: flex;
      align-items: center;
      .el-pagination {
        padding: 0;
        .el-pager,
        .el-pager li {
          margin-top: 2px;
          color: #afafaf;
          min-width: 50px;
          font-size: 17px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #d78673;
        }
        .el-pager li:hover {
          color: #d78673;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>